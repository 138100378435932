import _definePage_default_0 from '/opt/atlassian/pipelines/agent/build/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/atlassian/pipelines/agent/build/src/pages/access-control.vue?definePage&vue'
import _definePage_default_3 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/billing/bills.vue?definePage&vue'
import _definePage_default_4 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/chat.vue?definePage&vue'
import _definePage_default_5 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/email/index.vue?definePage&vue'
import _definePage_default_6 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/invoice/list/index.vue?definePage&vue'
import _definePage_default_7 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/fleet.vue?definePage&vue'
import _definePage_default_8 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/logistics.vue?definePage&vue'
import _definePage_default_9 from '/opt/atlassian/pipelines/agent/build/src/pages/apps/user/list/company-users.vue?definePage&vue'
import _definePage_default_10 from '/opt/atlassian/pipelines/agent/build/src/pages/dashboards/home.vue?definePage&vue'
import _definePage_default_11 from '/opt/atlassian/pipelines/agent/build/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_12 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/checkout.vue?definePage&vue'
import _definePage_default_13 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/help-center/index.vue?definePage&vue'
import _definePage_default_14 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/help-center/article/[title].vue?definePage&vue'
import _definePage_default_15 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/landing-page/index.vue?definePage&vue'
import _definePage_default_16 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/payment.vue?definePage&vue'
import _definePage_default_17 from '/opt/atlassian/pipelines/agent/build/src/pages/front-pages/pricing.vue?definePage&vue'
import _definePage_default_18 from '/opt/atlassian/pipelines/agent/build/src/pages/login.vue?definePage&vue'
import _definePage_default_19 from '/opt/atlassian/pipelines/agent/build/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_20 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_21 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/forgot-password-v1.vue?definePage&vue'
import _definePage_default_22 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/forgot-password-v2.vue?definePage&vue'
import _definePage_default_23 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/login-v1.vue?definePage&vue'
import _definePage_default_24 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/login-v2.vue?definePage&vue'
import _definePage_default_25 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-multi-steps.vue?definePage&vue'
import _definePage_default_26 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-v1.vue?definePage&vue'
import _definePage_default_27 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-v2.vue?definePage&vue'
import _definePage_default_28 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/reset-password-v1.vue?definePage&vue'
import _definePage_default_29 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/reset-password-v2.vue?definePage&vue'
import _definePage_default_30 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/two-steps-v1.vue?definePage&vue'
import _definePage_default_31 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/two-steps-v2.vue?definePage&vue'
import _definePage_default_32 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/verify-email-v1.vue?definePage&vue'
import _definePage_default_33 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/verify-email-v2.vue?definePage&vue'
import _definePage_default_34 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/misc/coming-soon.vue?definePage&vue'
import _definePage_default_35 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/misc/under-maintenance.vue?definePage&vue'
import _definePage_default_36 from '/opt/atlassian/pipelines/agent/build/src/pages/pages/user-profile/[tab].vue?definePage&vue'
import _definePage_default_37 from '/opt/atlassian/pipelines/agent/build/src/pages/register.vue?definePage&vue'
import _definePage_default_38 from '/opt/atlassian/pipelines/agent/build/src/pages/reset-password.vue?definePage&vue'
import _definePage_default_39 from '/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsOverviewTable.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/apps',
    /* internal name: 'apps' */
    /* no component */
    children: [
      {
        path: 'academy',
        /* internal name: 'apps-academy' */
        /* no component */
        children: [
          {
            path: 'course-details',
            name: 'apps-academy-course-details',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/academy/course-details.vue'),
            /* no children */
          },
          {
            path: 'dashboard',
            name: 'apps-academy-dashboard',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/academy/dashboard.vue'),
            /* no children */
          },
          {
            path: 'my-course',
            name: 'apps-academy-my-course',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/academy/my-course.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'billing',
        /* internal name: 'apps-billing' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'bills',
            name: 'apps-billing-bills',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/billing/bills.vue'),
            /* no children */
          },
  _definePage_default_3
  )
        ],
      },
      {
        path: 'calendar',
        name: 'apps-calendar',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/calendar.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'chat',
        name: 'apps-chat',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/chat.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'ecommerce',
        /* internal name: 'apps-ecommerce' */
        /* no component */
        children: [
          {
            path: 'customer',
            /* internal name: 'apps-ecommerce-customer' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-customer-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-customer-details-id',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/customer/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-customer-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-customer-list',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/customer/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'manage-review',
            name: 'apps-ecommerce-manage-review',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/manage-review.vue'),
            /* no children */
          },
          {
            path: 'order',
            /* internal name: 'apps-ecommerce-order' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-order-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-order-details-id',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/order/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-order-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-order-list',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/order/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'product',
            /* internal name: 'apps-ecommerce-product' */
            /* no component */
            children: [
              {
                path: 'add',
                /* internal name: 'apps-ecommerce-product-add' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-add',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/product/add/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'category-list',
                name: 'apps-ecommerce-product-category-list',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/product/category-list.vue'),
                /* no children */
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-product-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-list',
                    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/product/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'referrals',
            name: 'apps-ecommerce-referrals',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/referrals.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: 'apps-ecommerce-settings',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/ecommerce/settings.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'email',
        /* internal name: 'apps-email' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'apps-email',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/email/index.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      },
      {
        path: 'invoice',
        /* internal name: 'apps-invoice' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'apps-invoice-add' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-add',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/invoice/add/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'apps-invoice-edit' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-edit-id',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/invoice/edit/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'list',
            /* internal name: 'apps-invoice-list' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'apps-invoice-list',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/invoice/list/index.vue'),
                /* no children */
              },
  _definePage_default_6
  )
            ],
          },
          {
            path: 'preview',
            /* internal name: 'apps-invoice-preview' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-preview-id',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/invoice/preview/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'logistics',
        /* internal name: 'apps-logistics' */
        /* no component */
        children: [
          {
            path: 'co2',
            name: 'apps-logistics-co2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/co2.vue'),
            /* no children */
          },
          {
            path: 'dashboard',
            name: 'apps-logistics-dashboard',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/dashboard.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'fleet',
            name: 'apps-logistics-fleet',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/fleet.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'logistics',
            name: 'apps-logistics-logistics',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/logistics/logistics.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
      {
        path: 'permissions',
        /* internal name: 'apps-permissions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-permissions',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/permissions/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'roles',
        /* internal name: 'apps-roles' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-roles',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/roles/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'user',
        /* internal name: 'apps-user' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-user-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-user-list',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/user/list/index.vue'),
                /* no children */
              },
  _mergeRouteRecord(
              {
                path: 'company-users',
                name: 'apps-user-list-company-users',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/user/list/company-users.vue'),
                /* no children */
              },
  _definePage_default_9
  )
            ],
          },
          {
            path: 'view',
            /* internal name: 'apps-user-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-user-view-id',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/apps/user/view/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/apps/email/:filter',
    name: 'apps-email-filter',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/apps/email/:label',
    name: 'apps-email-label',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/charts',
    /* internal name: 'charts' */
    /* no component */
    children: [
      {
        path: 'apex-chart',
        name: 'charts-apex-chart',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/charts/apex-chart.vue'),
        /* no children */
      },
      {
        path: 'chartjs',
        name: 'charts-chartjs',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/charts/chartjs.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/components',
    /* internal name: 'components' */
    /* no component */
    children: [
      {
        path: 'alert',
        name: 'components-alert',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/alert.vue'),
        /* no children */
      },
      {
        path: 'avatar',
        name: 'components-avatar',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/avatar.vue'),
        /* no children */
      },
      {
        path: 'badge',
        name: 'components-badge',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/badge.vue'),
        /* no children */
      },
      {
        path: 'button',
        name: 'components-button',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/button.vue'),
        /* no children */
      },
      {
        path: 'chip',
        name: 'components-chip',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/chip.vue'),
        /* no children */
      },
      {
        path: 'confirmation-dialog',
        name: 'components-confirmation-dialog',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/confirmation-dialog.vue'),
        /* no children */
      },
      {
        path: 'dialog',
        name: 'components-dialog',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/dialog.vue'),
        /* no children */
      },
      {
        path: 'expansion-panel',
        name: 'components-expansion-panel',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/expansion-panel.vue'),
        /* no children */
      },
      {
        path: 'list',
        name: 'components-list',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/list.vue'),
        /* no children */
      },
      {
        path: 'menu',
        name: 'components-menu',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/menu.vue'),
        /* no children */
      },
      {
        path: 'pagination',
        name: 'components-pagination',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/pagination.vue'),
        /* no children */
      },
      {
        path: 'progress-circular',
        name: 'components-progress-circular',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/progress-circular.vue'),
        /* no children */
      },
      {
        path: 'progress-linear',
        name: 'components-progress-linear',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/progress-linear.vue'),
        /* no children */
      },
      {
        path: 'snackbar',
        name: 'components-snackbar',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/snackbar.vue'),
        /* no children */
      },
      {
        path: 'tabs',
        name: 'components-tabs',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/tabs.vue'),
        /* no children */
      },
      {
        path: 'timeline',
        name: 'components-timeline',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/timeline.vue'),
        /* no children */
      },
      {
        path: 'tooltip',
        name: 'components-tooltip',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/components/tooltip.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/dashboards',
    /* internal name: 'dashboards' */
    /* no component */
    children: [
      {
        path: 'analytics',
        name: 'dashboards-analytics',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/dashboards/analytics.vue'),
        /* no children */
      },
      {
        path: 'crm',
        name: 'dashboards-crm',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/dashboards/crm.vue'),
        /* no children */
      },
      {
        path: 'ecommerce',
        name: 'dashboards-ecommerce',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/dashboards/ecommerce.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'home',
        name: 'dashboards-home',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/dashboards/home.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/extensions',
    /* internal name: 'extensions' */
    /* no component */
    children: [
      {
        path: 'swiper',
        name: 'extensions-swiper',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/extensions/swiper.vue'),
        /* no children */
      },
      {
        path: 'tour',
        name: 'extensions-tour',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/extensions/tour.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_11
  ),
  {
    path: '/forms',
    /* internal name: 'forms' */
    /* no component */
    children: [
      {
        path: 'autocomplete',
        name: 'forms-autocomplete',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/autocomplete.vue'),
        /* no children */
      },
      {
        path: 'checkbox',
        name: 'forms-checkbox',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/checkbox.vue'),
        /* no children */
      },
      {
        path: 'combobox',
        name: 'forms-combobox',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/combobox.vue'),
        /* no children */
      },
      {
        path: 'custom-input',
        name: 'forms-custom-input',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/custom-input.vue'),
        /* no children */
      },
      {
        path: 'date-time-picker',
        name: 'forms-date-time-picker',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/date-time-picker.vue'),
        /* no children */
      },
      {
        path: 'editors',
        name: 'forms-editors',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/editors.vue'),
        /* no children */
      },
      {
        path: 'file-input',
        name: 'forms-file-input',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/file-input.vue'),
        /* no children */
      },
      {
        path: 'form-layouts',
        name: 'forms-form-layouts',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/form-layouts.vue'),
        /* no children */
      },
      {
        path: 'form-validation',
        name: 'forms-form-validation',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/form-validation.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-icons',
        name: 'forms-form-wizard-icons',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/form-wizard-icons.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-numbered',
        name: 'forms-form-wizard-numbered',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/form-wizard-numbered.vue'),
        /* no children */
      },
      {
        path: 'radio',
        name: 'forms-radio',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/radio.vue'),
        /* no children */
      },
      {
        path: 'range-slider',
        name: 'forms-range-slider',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/range-slider.vue'),
        /* no children */
      },
      {
        path: 'rating',
        name: 'forms-rating',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/rating.vue'),
        /* no children */
      },
      {
        path: 'select',
        name: 'forms-select',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/select.vue'),
        /* no children */
      },
      {
        path: 'slider',
        name: 'forms-slider',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/slider.vue'),
        /* no children */
      },
      {
        path: 'switch',
        name: 'forms-switch',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/switch.vue'),
        /* no children */
      },
      {
        path: 'textarea',
        name: 'forms-textarea',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/textarea.vue'),
        /* no children */
      },
      {
        path: 'textfield',
        name: 'forms-textfield',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/forms/textfield.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/front-pages',
    /* internal name: 'front-pages' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'checkout',
        name: 'front-pages-checkout',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/checkout.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
      {
        path: 'help-center',
        /* internal name: 'front-pages-help-center' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-help-center',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/help-center/index.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
          {
            path: 'article',
            /* internal name: 'front-pages-help-center-article' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':title',
                name: 'front-pages-help-center-article-title',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/help-center/article/[title].vue'),
                /* no children */
              },
  _definePage_default_14
  )
            ],
          }
        ],
      },
      {
        path: 'landing-page',
        /* internal name: 'front-pages-landing-page' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-landing-page',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/landing-page/index.vue'),
            /* no children */
          },
  _definePage_default_15
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'payment',
        name: 'front-pages-payment',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/payment.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'pricing',
        name: 'front-pages-pricing',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/front-pages/pricing.vue'),
        /* no children */
      },
  _definePage_default_17
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_18
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_19
  ),
  {
    path: '/pages',
    /* internal name: 'pages' */
    /* no component */
    children: [
      {
        path: 'account-settings',
        /* internal name: 'pages-account-settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-account-settings-tab',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/account-settings/[tab].vue'),
            /* no children */
          },
  _definePage_default_20
  )
        ],
      },
      {
        path: 'authentication',
        /* internal name: 'pages-authentication' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'forgot-password-v1',
            name: 'pages-authentication-forgot-password-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/forgot-password-v1.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'forgot-password-v2',
            name: 'pages-authentication-forgot-password-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/forgot-password-v2.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
  _mergeRouteRecord(
          {
            path: 'login-v1',
            name: 'pages-authentication-login-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/login-v1.vue'),
            /* no children */
          },
  _definePage_default_23
  ),
  _mergeRouteRecord(
          {
            path: 'login-v2',
            name: 'pages-authentication-login-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/login-v2.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
  _mergeRouteRecord(
          {
            path: 'register-multi-steps',
            name: 'pages-authentication-register-multi-steps',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-multi-steps.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'register-v1',
            name: 'pages-authentication-register-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-v1.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'register-v2',
            name: 'pages-authentication-register-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/register-v2.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v1',
            name: 'pages-authentication-reset-password-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/reset-password-v1.vue'),
            /* no children */
          },
  _definePage_default_28
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v2',
            name: 'pages-authentication-reset-password-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/reset-password-v2.vue'),
            /* no children */
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v1',
            name: 'pages-authentication-two-steps-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/two-steps-v1.vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v2',
            name: 'pages-authentication-two-steps-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/two-steps-v2.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v1',
            name: 'pages-authentication-verify-email-v1',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/verify-email-v1.vue'),
            /* no children */
          },
  _definePage_default_32
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v2',
            name: 'pages-authentication-verify-email-v2',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/authentication/verify-email-v2.vue'),
            /* no children */
          },
  _definePage_default_33
  )
        ],
      },
      {
        path: 'cards',
        /* internal name: 'pages-cards' */
        /* no component */
        children: [
          {
            path: 'card-actions',
            name: 'pages-cards-card-actions',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/cards/card-actions.vue'),
            /* no children */
          },
          {
            path: 'card-advance',
            name: 'pages-cards-card-advance',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/cards/card-advance.vue'),
            /* no children */
          },
          {
            path: 'card-basic',
            name: 'pages-cards-card-basic',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/cards/card-basic.vue'),
            /* no children */
          },
          {
            path: 'card-statistics',
            name: 'pages-cards-card-statistics',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/cards/card-statistics.vue'),
            /* no children */
          },
          {
            path: 'card-widgets',
            name: 'pages-cards-card-widgets',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/cards/card-widgets.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'dialog-examples',
        /* internal name: 'pages-dialog-examples' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-dialog-examples',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/dialog-examples/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'faq',
        name: 'pages-faq',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/faq.vue'),
        /* no children */
      },
      {
        path: 'icons',
        name: 'pages-icons',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/icons.vue'),
        /* no children */
      },
      {
        path: 'misc',
        /* internal name: 'pages-misc' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'coming-soon',
            name: 'pages-misc-coming-soon',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/misc/coming-soon.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
  _mergeRouteRecord(
          {
            path: 'under-maintenance',
            name: 'pages-misc-under-maintenance',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/misc/under-maintenance.vue'),
            /* no children */
          },
  _definePage_default_35
  )
        ],
      },
      {
        path: 'pricing',
        name: 'pages-pricing',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/pricing.vue'),
        /* no children */
      },
      {
        path: 'typography',
        name: 'pages-typography',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/typography.vue'),
        /* no children */
      },
      {
        path: 'user-profile',
        /* internal name: 'pages-user-profile' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-user-profile-tab',
            component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/pages/user-profile/[tab].vue'),
            /* no children */
          },
  _definePage_default_36
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_37
  ),
  _mergeRouteRecord(
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/reset-password.vue'),
    /* no children */
  },
  _definePage_default_38
  ),
  {
    path: '/tables',
    /* internal name: 'tables' */
    /* no component */
    children: [
      {
        path: 'data-table',
        name: 'tables-data-table',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/tables/data-table.vue'),
        /* no children */
      },
      {
        path: 'simple-table',
        name: 'tables-simple-table',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/tables/simple-table.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/views',
    /* internal name: 'views' */
    /* no component */
    children: [
      {
        path: 'apps',
        /* internal name: 'views-apps' */
        /* no component */
        children: [
          {
            path: 'invoice',
            /* internal name: 'views-apps-invoice' */
            /* no component */
            children: [
              {
                path: 'InvoiceAddPaymentDrawer',
                name: 'views-apps-invoice-invoice-add-payment-drawer',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/invoice/InvoiceAddPaymentDrawer.vue'),
                /* no children */
              },
              {
                path: 'InvoiceEditable',
                name: 'views-apps-invoice-invoice-editable',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/invoice/InvoiceEditable.vue'),
                /* no children */
              },
              {
                path: 'InvoiceProductEdit',
                name: 'views-apps-invoice-invoice-product-edit',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/invoice/InvoiceProductEdit.vue'),
                /* no children */
              },
              {
                path: 'InvoiceSendInvoiceDrawer',
                name: 'views-apps-invoice-invoice-send-invoice-drawer',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/invoice/InvoiceSendInvoiceDrawer.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'logistics',
            /* internal name: 'views-apps-logistics' */
            /* no component */
            children: [
              {
                path: 'LogisticsCardStatistics',
                name: 'views-apps-logistics-logistics-card-statistics',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsCardStatistics.vue'),
                /* no children */
              },
              {
                path: 'LogisticsDeliveryExpectations',
                name: 'views-apps-logistics-logistics-delivery-expectations',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsDeliveryExpectations.vue'),
                /* no children */
              },
              {
                path: 'LogisticsDeliveryPerformance',
                name: 'views-apps-logistics-logistics-delivery-performance',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsDeliveryPerformance.vue'),
                /* no children */
              },
              {
                path: 'LogisticsOrderByCountries',
                name: 'views-apps-logistics-logistics-order-by-countries',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsOrderByCountries.vue'),
                /* no children */
              },
  _mergeRouteRecord(
              {
                path: 'LogisticsOverviewTable',
                name: 'views-apps-logistics-logistics-overview-table',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsOverviewTable.vue'),
                /* no children */
              },
  _definePage_default_39
  ),
              {
                path: 'LogisticsShipmentStatistics',
                name: 'views-apps-logistics-logistics-shipment-statistics',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsShipmentStatistics.vue'),
                /* no children */
              },
              {
                path: 'LogisticsVehicleOverview',
                name: 'views-apps-logistics-logistics-vehicle-overview',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/apps/logistics/LogisticsVehicleOverview.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'charts',
        /* internal name: 'views-charts' */
        /* no component */
        children: [
          {
            path: 'apex-chart',
            /* internal name: 'views-charts-apex-chart' */
            /* no component */
            children: [
              {
                path: 'ApexChartAreaChart',
                name: 'views-charts-apex-chart-apex-chart-area-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartAreaChart.vue'),
                /* no children */
              },
              {
                path: 'ApexChartBalance',
                name: 'views-charts-apex-chart-apex-chart-balance',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartBalance.vue'),
                /* no children */
              },
              {
                path: 'ApexChartDailySalesStates',
                name: 'views-charts-apex-chart-apex-chart-daily-sales-states',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartDailySalesStates.vue'),
                /* no children */
              },
              {
                path: 'ApexChartDataScience',
                name: 'views-charts-apex-chart-apex-chart-data-science',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartDataScience.vue'),
                /* no children */
              },
              {
                path: 'ApexChartExpenseRatio',
                name: 'views-charts-apex-chart-apex-chart-expense-ratio',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartExpenseRatio.vue'),
                /* no children */
              },
              {
                path: 'ApexChartHorizontalBar',
                name: 'views-charts-apex-chart-apex-chart-horizontal-bar',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartHorizontalBar.vue'),
                /* no children */
              },
              {
                path: 'ApexChartMobileComparison',
                name: 'views-charts-apex-chart-apex-chart-mobile-comparison',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartMobileComparison.vue'),
                /* no children */
              },
              {
                path: 'ApexChartNewTechnologiesData',
                name: 'views-charts-apex-chart-apex-chart-new-technologies-data',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartNewTechnologiesData.vue'),
                /* no children */
              },
              {
                path: 'ApexChartStatistics',
                name: 'views-charts-apex-chart-apex-chart-statistics',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartStatistics.vue'),
                /* no children */
              },
              {
                path: 'ApexChartStocksPrices',
                name: 'views-charts-apex-chart-apex-chart-stocks-prices',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/apex-chart/ApexChartStocksPrices.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'chartjs',
            /* internal name: 'views-charts-chartjs' */
            /* no component */
            children: [
              {
                path: 'ChartJsBarChart',
                name: 'views-charts-chartjs-chart-js-bar-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsBarChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsBubbleChart',
                name: 'views-charts-chartjs-chart-js-bubble-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsBubbleChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsHorizontalBarChart',
                name: 'views-charts-chartjs-chart-js-horizontal-bar-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsHorizontalBarChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsLineAreaChart',
                name: 'views-charts-chartjs-chart-js-line-area-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsLineAreaChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsLineChart',
                name: 'views-charts-chartjs-chart-js-line-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsLineChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsPolarAreaChart',
                name: 'views-charts-chartjs-chart-js-polar-area-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsPolarAreaChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsRadarChart',
                name: 'views-charts-chartjs-chart-js-radar-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsRadarChart.vue'),
                /* no children */
              },
              {
                path: 'ChartJsScatterChart',
                name: 'views-charts-chartjs-chart-js-scatter-chart',
                component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/views/charts/chartjs/ChartJsScatterChart.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/wizard-examples',
    /* internal name: 'wizard-examples' */
    /* no component */
    children: [
      {
        path: 'checkout',
        name: 'wizard-examples-checkout',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/wizard-examples/checkout.vue'),
        /* no children */
      },
      {
        path: 'create-deal',
        name: 'wizard-examples-create-deal',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/wizard-examples/create-deal.vue'),
        /* no children */
      },
      {
        path: 'property-listing',
        name: 'wizard-examples-property-listing',
        component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/wizard-examples/property-listing.vue'),
        /* no children */
      }
    ],
  }
]
