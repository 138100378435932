<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/@core/stores/auth'
import { BillStatus } from '@/@core/stores/bill'
import type { Contract } from '@/@core/stores/contract'
import { ContractStatus, useContractStore } from '@/@core/stores/contract'
import { track } from '@/boot/mixpanel'
import BillContractHeader from '@/views/apps/billing/BillContractHeader.vue'
import BillContractTable from '@/views/apps/billing/BillContractTable.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const contractStore = useContractStore()
const authStore = useAuthStore()

const contracts = ref<Contract[]>([])
const selectedStatus = ref<string | null>(null)
const startDate = ref<string | null>(null)
const endDate = ref<string | null>(null)

const isContractClosed = (contract: Contract) => contract.status === ContractStatus.PAID || contract.status === ContractStatus.COMPLETED

const statusOptions = [
  { text: t('All'), value: null },
  ...Object.values(BillStatus).map(status => ({ text: t(status), value: status })),
]

onMounted(async () => {
  try {
    await contractStore.fetchContracts(authStore.user.id, authStore.access_token)
    contracts.value = contractStore.contracts
  }
  catch (error) {
    console.error('Failed to fetch contracts:', error)
  }
})

watch(selectedStatus, () => {
  track('Selected bill status filter')
})

watch(startDate, () => {
  track('Changed Start Due Date filter')
})

watch(endDate, () => {
  track('Changed End Due Date filter')
})

const calculateContractStatus = (bills, contract) => {
  const contractClosed = isContractClosed(contract)

  const hasOverdueBills = bills.some(bill => bill.status === BillStatus.OVERDUE && !contractClosed)
  const hasPendingBills = bills.some(bill => bill.status === BillStatus.PENDING && !contractClosed)
  const allBillsPaid = bills.every(bill => bill.status === BillStatus.PAID) || contractClosed

  if (bills.length === 0 && !contractClosed)
    return null
  if (hasOverdueBills)
    return BillStatus.OVERDUE
  if (hasPendingBills)
    return BillStatus.PENDING
  if (allBillsPaid)
    return BillStatus.PAID

  return null
}

const filterBillsByDate = bills => {
  return bills.filter(bill => {
    const matchesStartDate = startDate.value ? new Date(bill.due_date) >= new Date(startDate.value) : true
    const matchesEndDate = endDate.value ? new Date(bill.due_date) <= new Date(endDate.value) : true

    return matchesStartDate && matchesEndDate
  })
}

const filteredContracts = computed(() => {
  return contracts.value.filter(contract => {
    const { bills = [] } = contract

    const filteredBillsByDate = filterBillsByDate(bills)

    const contractStatus = calculateContractStatus(bills, contract)

    if (selectedStatus.value)
      return contractStatus === selectedStatus.value

    if (!startDate.value && !endDate.value)
      return true

    return filteredBillsByDate.length > 0
  })
})

const sortedContracts = computed(() => {
  const contractsToSort = [...filteredContracts.value]

  const mostRecentContract = contractsToSort.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]

  const overdueContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.OVERDUE && !isContractClosed(contract))
  const pendingContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.PENDING && !isContractClosed(contract))
  const paidContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.PAID || isContractClosed(contract))

  const remainingContracts = contractsToSort.filter(contract => !overdueContracts.includes(contract)
                                                                && !pendingContracts.includes(contract)
                                                                && !paidContracts.includes(contract))

  const orderedContracts = []

  if (mostRecentContract)
    orderedContracts.push(mostRecentContract)

  orderedContracts.push(...overdueContracts)
  orderedContracts.push(...pendingContracts)
  orderedContracts.push(...paidContracts)
  orderedContracts.push(...remainingContracts)

  return Array.from(new Set(orderedContracts.map(contract => contract.id)))
    .map(id => orderedContracts.find(contract => contract.id === id))
})

const updateContractStatus = updatedBill => {
  const contractToUpdate = contracts.value.find(contract => contract.id === updatedBill.contract)

  if (contractToUpdate) {
    const billIndex = contractToUpdate.bills.findIndex(bill => bill.id === updatedBill.id)

    if (billIndex !== -1)
      contractToUpdate.bills[billIndex] = { ...updatedBill }
    else if (!updatedBill.deleted)
      contractToUpdate.bills.push(updatedBill)

    if (updatedBill.deleted)
      contractToUpdate.bills = contractToUpdate.bills.filter(bill => bill.id !== updatedBill.id)

    const newContractStatus = calculateContractStatus(contractToUpdate.bills, contractToUpdate)

    console.log(`Contrato ${contractToUpdate.id} actualizado con estado: ${newContractStatus}`)
  }
}
</script>

<template>
  <div>
    <div class="filter-container mb-4">
      <VSelect
        v-model="selectedStatus"
        :items="statusOptions"
        item-title="text"
        item-value="value"
        :label="t('Bill Status')"
        dense
        hide-details
      />
      <div class="date-range-filter">
        <VTextField
          v-model="startDate"
          :label="t('Start Due Date')"
          type="date"
          dense
          hide-details
        />
        <VTextField
          v-model="endDate"
          :label="t('End Due Date')"
          type="date"
          dense
          hide-details
        />
      </div>
    </div>
    <div
      v-if="sortedContracts.length === 0"
      class="text-center py-4"
    >
      <p>{{ t('No contracts available') }}</p>
    </div>
    <div
      v-for="contract in sortedContracts"
      v-else
      :key="contract.id"
    >
      <BillContractHeader
        :contract="contract"
        class="contract-header"
      />
      <BillContractTable
        :contract-id="contract.id"
        :bills="contract.bills"
        :is-seller="contract.is_seller"
        :is-buyer="contract.is_buyer"
        :contract-status="contract.status"
        class="contract-table"
        @bill-updated="updateContractStatus"
      />
      <VDivider class="my-4" />
    </div>
  </div>
</template>

<style scoped>
.filter-container {
  display: flex;
  justify-content: space-between;
  margin-block-end: 20px;
}

.date-range-filter {
  display: flex;
  gap: 10px;
}

.contract-header,
.contract-table {
  padding: 0;
  margin: 0;
}

.my-4 {
  margin-block: 16px;
  margin-inline: 0;
}
</style>
