{
  "UI Elements": "UI Elements",
  "Forms & Tables": "Forms & Tables",
  "Pages": "Pages",
  "Charts & Maps": "Charts & Maps",
  "Others": "Others",
  "Typography": "Typography",
  "Cards": "Cards",
  "Basic": "Basic",
  "Advance": "Advance",
  "Widgets": "Widgets",
  "Components": "Components",
  "Alert": "Alert",
  "Close Alert": "Close Alert",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Button",
  "Calendar": "Calendar",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress Circular": "Progress Circular",
  "Progress Linear": "Progress Linear",
  "Autocomplete": "Autocomplete",
  "Tooltip": "Tooltip",
  "Slider": "Slider",
  "Date Time Picker": "Date Time Picker",
  "Select": "Select",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Rating",
  "File Input": "File Input",
  "Otp Input": "Otp Input",
  "Form Layout": "Form Layout",
  "Form Validation": "Form Validation",
  "Charts": "Charts",
  "Apex Chart": "Apex Chart",
  "Chartjs": "Chartjs",
  "Account Settings": "Account Settings",
  "User Profile": "User Profile",
  "FAQ": "FAQ",
  "Dialog Examples": "Dialog Examples",
  "Pricing": "Pricing",
  "List": "List",
  "Edit": "Edit",
  "Nav Levels": "Nav Levels",
  "Level 2.1": "Level 2.1",
  "Level 2.2": "Level 2.2",
  "Level 3.1": "Level 3.1",
  "Level 3.2": "Level 3.2",
  "Raise Support": "Raise Support",
  "Documentation": "Documentation",
  "Dashboards": "Dashboards",
  "Analytics": "Analytics",
  "Apps & Pages": "Apps & Pages",
  "Email": "Email",
  "Chat": "Chat",
  "Invoice": "Invoice",
  "Preview": "Preview",
  "Add": "Add",
  "User": "User",
  "View": "View",
  "Login v1": "Login v1",
  "Login v2": "Login v2",
  "Login": "Login",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Forget Password v1": "Forget Password v1",
  "Forget Password v2": "Forget Password v2",
  "Forgot Password v1": "Forgot Password v1",
  "Forgot Password v2": "Forgot Password v2",
  "Forgot Password": "Forgot Password",
  "Reset Password v1": "Reset Password v1",
  "Reset Password v2": "Reset Password v2",
  "Reset Password": "Reset Password",
  "Miscellaneous": "Miscellaneous",
  "Coming Soon": "Coming Soon",
  "Not Authorized": "Not Authorized",
  "Under Maintenance": "Under Maintenance",
  "Error": "Error",
  "Statistics": "Statistics",
  "Actions": "Actions",
  "Access Control": "Access Control",
  "User Interface": "User Interface",
  "CRM": "CRM",
  "eCommerce": "eCommerce",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Expansion Panel",
  "Combobox": "Combobox",
  "Textfield": "Textfield",
  "Range Slider": "Range Slider",
  "Menu": "Menu",
  "Snackbar": "Snackbar",
  "Tabs": "Tabs",
  "Form Elements": "Form Elements",
  "Form Layouts": "Form Layouts",
  "Authentication": "Authentication",
  "Page Not Found - 404": "Page Not Found - 404",
  "Not Authorized - 401": "Not Authorized - 401",
  "Server Error - 500": "Server Error - 500",
  "2": "2",
  "Forms": "Forms",
  "Timeline": "Timeline",
  "Disabled Menu": "Disabled Menu",
  "Help Center": "Help Center",
  "Verify Email": "Verify Email",
  "Verify Email v1": "Verify Email v1",
  "Verify Email v2": "Verify Email v2",
  "Two Steps": "Two Steps",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Custom Input": "Custom Input",
  "Extensions": "Extensions",
  "Tour": "Tour",
  "Register Multi-Steps": "Register Multi-Steps",
  "Wizard Examples": "Wizard Examples",
  "Checkout": "Checkout",
  "Create Deal": "Create Deal",
  "Property Listing": "Property Listing",
  "Roles & Permissions": "Roles & Permissions",
  "Roles": "Roles",
  "Simple Table": "Simple Table",
  "Tables": "Tables",
  "Data Table": "Data Table",
  "Permissions": "Permissions",
  "Apps": "Apps",
  "Misc": "Misc",
  "Wizard Pages": "Wizard Pages",
  "Form Wizard": "Form Wizard",
  "Numbered": "Numbered",
  "3": "3",
  "ecommerce": "ecommerce",
  "Ecommerce": "Ecommerce",
  "Editors": "Editors",
  "Front Pages": "Front Pages",
  "Landing": "Landing",
  "checkout": "checkout",
  "Payment": "Payment",
  "Swiper": "Swiper",
  "Product": "Product",
  "Category": "Category",
  "Order": "Order",
  "Details": "Details",
  "Customer": "Customer",
  "Manage Review": "Manage Review",
  "Referrals": "Referrals",
  "Settings": "Settings",
  "Overview": "Overview",
  "My Course": "My Course",
  "Course Details": "Course Details",
  "Academy": "Academy",
  "Logistics": "Logistics",
  "Dashboard": "Dashboard",
  "Fleet": "Fleet",
  "5": "5",
  "10": "10",
  "20": "20",
  "25": "25",
  "50": "50",
  "100": "100",
  "Contracts": "Contracts",
  "Contract": "Contract",
  "Operation type": "Operation type",
  "Business": "Business",
  "Commodity": "Commodity",
  "Position": "Position",
  "Price €/T(Tons)": "Price €/T(Tons)",
  "Amount T(Tons)": "Amount T(Tons)",
  "Start": "Start",
  "End": "End",
  "Contract status": "Contract status",
  "Received": "Received",
  "Cancelled": "Cancelled",
  "Contract completed": "Contract completed",
  "Paid": "Paid",
  "Logistics_completed": "Logistics completed",
  "P. Date": "P. Date",
  "No contracts available": "No contracts available",
  "Loading contracts...": "Loading contracts...",
  "Downloading contract...": "Downloading contract...",
  "Buy": "Buy",
  "Sell": "Sell",
  "You must enter an email": "You must enter an email",
  "It must be a valid email": "It must be a valid email",
  "You must enter a valid password": "You must enter a valid password",
  "The password must be at least 8 characters long": "The password must be at least 8 characters long",
  "Both Passwords must be the same": "Both Passwords must be the same",
  "Forgot Password? 🔒": "Forgot Password? 🔒",
  "Enter your email and we´ll send you instructions to reset your password": "Enter your email and we´ll send you instructions to reset your password",
  "Send Reset Link": "Send Reset Link",
  "Reset Password 🔒": "Reset Password 🔒",
  "Set New Password": "Set New Password",
  "Back to login": "Back to login",
  "Password reset link sent successfully.": "Password reset link sent successfully.",
  "New Password": "New Password",
  "Confirm Password": "Confirm Password",
  "Failed to send reset link. Please try again later.": "Failed to send reset link. Please try again later.",
  "An error has occurred while resetting the password. Please, try again later.": "An error has occurred while resetting the password. Please, try again later.",
  "Home": "Home",
  "Sold": "Sold",
  "Bought": "Bought",
  "January": "January",
  "February": "February",
  "March": "March",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "August": "August",
  "September": "September",
  "October": "October",
  "November": "November",
  "December": "December",
  "Average prices": "Average prices",
  "Select Product": "Select Product",
  "Welcome to AbastoresOS": "Welcome to AbastoresOS",
  "Please sign-in to your account": "Please sign-in to your account",
  "New on our platform?": "New on our platform?",
  "Create an account": "Create an account",
  "Remember me": "Remember me",
  "Password": "Password",
  "or": "or",
  "We´re currently working on enhancing the system. Please try again later.": "We´re currently working on enhancing the system. Please try again later.",
  "Manage trucks": "Manage trucks",
  "Plate": "Plate",
  "Date/Time": "Date/Time",
  "The seller will be notified by email of the license plate number and date to pick up the goods. Are you sure?": "The seller will be notified by email of the license plate number and date to pick up the goods. Are you sure?",
  "The buyer will be notified by email of the license plate number and date to pick up the goods. Are you sure?": "The buyer will be notified by email of the license plate number and date to pick up the goods. Are you sure?",
  "Pickup date": "Pickup date",
  "Cancel": "Cancel",
  "Send": "Send",
  "Confirm shipping?": "Confirm shipping?",
  "Send to seller": "Send to seller",
  "Send to buyer": "Send to buyer",
  "Clear": "Clear",
  "Enter plate": "Enter plate",
  "Enter Date/Time": "Enter Date/Time",
  "Plate is required": "Plate is required",
  "Date/Time is required": "Date/Time is required",
  "Trucks": "Trucks",
  "Name": "Name",
  "User successfully unlinked.": "User successfully unlinked.",
  "There was a problem unlinking the account. Please try again later.": "There was a problem unlinking the account. Please try again later.",
  "User added successfully.": "User added successfully.",
  "There was a problem adding the new user. Please try again later.": "There was a problem adding the new user. Please try again later.",
  "Add New User": "Add New User",
  "Unlink User": "Unlink User",
  "Are you sure you want to unlink this user from the Company?": "Are you sure you want to unlink this user from the Company?",
  "Search User": "Search User",
  "All": "All",
  "Confirm": "Confirm",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Company": "Company",
  "Phone Number": "Phone Number",
  "Submit": "Submit",
  "Users": "Users",
  "Showing": "Showing",
  "to": "to",
  "of": "of",
  "entries": "entries",
  "This field is required": "This field is required",
  "The Email field must be a valid email": "The Email field must be a valid email",
  "Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars": "Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars",
  "The Confirm Password field confirmation does not match": "The Confirm Password field confirmation does not match",
  "Enter number between {min} and {max}": "Enter number between {min} and {max}",
  "This field must be an integer": "This field must be an integer",
  "The Regex field format is invalid": "The Regex field format is invalid",
  "The Alpha field may only contain alphabetic characters": "The Alpha field may only contain alphabetic characters",
  "URL is invalid": "URL is invalid",
  "The length of the Characters field must be {length} characters.": "The length of the Characters field must be {length} characters.",
  "All Character are not valid": "All Character are not valid",
  "Bills": "Bills",
  "Bill Status": "Bill Status",
  "Start Due Date": "Start Due Date",
  "End Due Date": "End Due Date",
  "PAID": "PAID",
  "PENDING": "PENDING",
  "OVERDUE": "OVERDUE",
  "NEW": "NEW",
  "Show Bills": "Show Bills",
  "Due Date": "Due Date",
  "Status": "Status",
  "Attach file": "Attach file",
  "Add Bill": "Add Bill",
  "No bills found": "No bills found",
  "Mark as Paid": "Mark as Paid",
  "Delete": "Delete",
  "Edit Bill": "Edit Bill",
  "Bill added successfully": "Bill added successfully",
  "An error occurred while adding the bill. Please try again later": "An error occurred while adding the bill. Please try again later",
  "Please, attach a document": "Please, attach a document",
  "Bill updated successfully": "Bill updated successfully",
  "An error occurred while updating the bill. Please try again later": "An error occurred while updating the bill. Please try again later",
  "Bill deleted successfully": "Bill deleted successfully",
  "An error occurred while deleting the bill. Please try again later": "An error occurred while deleting the bill. Please try again later",
  "Bill marked as paid": "Bill marked as paid",
  "An error occurred while changing the bill status. Please try again later": "An error occurred while changing the bill status. Please try again later",
  "Delete Bill": "Delete Bill",
  "Are you sure you want to delete this bill?": "Are you sure you want to delete this bill?",
  "Mark Bill as Paid": "Mark Bill as Paid",
  "Are you sure you want to mark this bill as paid?": "Are you sure you want to mark this bill as paid?",
  "contractCompleted": "The contract {contractId} has been completed. It has been moved to the completed list.",
  "Pending": "Pending",
  "CompletedTag": "Completed",
  "orderAddedSuccessfully": "The order with plate {newPlate} has been successfully added",
  "There was an error when adding the plate. Please, try again later": "There was an error when adding the plate. Please, try again later",
  "See trucks": "See trucks",
  "In this operation, the other company handles the adding of the plates.": "In this operation, the other company handles the adding of the plates.",
  "No license plate data is available.": "No license plate data is available.",
  "License plate data has not been entered yet.": "License plate data has not been entered yet.",
  "$vuetify": {
    "badge": "Badge",
    "noDataText": "No data available",
    "close": "Close",
    "open": "open",
    "loading": "loading",
    "carousel": {
      "ariaLabel": {
        "delimiter": "delimiter"
      }
    },
    "dataFooter": {
      "itemsPerPageText": "Items per page:",
      "itemsPerPageAll": "All",
      "pageText": "{0}-{1} of {2}",
      "firstPage": "First Page",
      "prevPage": "Previous Page",
      "nextPage": "Next Page",
      "lastPage": "Last Page"
    },
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "first": "first",
        "last": "last",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize",
      "otp": "otp"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  }
}
