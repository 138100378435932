import * as Sentry from '@sentry/vue'
import Axios from 'axios'
import axiosRetry, { exponentialDelay, isNetworkOrIdempotentRequestError } from 'axios-retry'
import qs from 'qs'
import { useAuthStore } from '@/@core/stores/auth'

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
})

axios.interceptors.request.use(async request => {
  const authStore = useAuthStore()
  if (authStore.isLoggedIn) {
    request.headers = request.headers || {}
    request.headers.Authorization = `Bearer ${authStore.access_token}`
  }

  return request
})

axiosRetry(axios, {
  retries: 3,
  retryDelay: exponentialDelay,
  retryCondition: isNetworkOrIdempotentRequestError,
})

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.config) {
      Sentry.withScope(scope => {
        scope.setContext('axios', {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          data: error.config.data ? formatFormData(error.config.data) : 'No data sent',
        })

        if (error.response) {
          scope.setContext('response', {
            status: error.response.status,
            data: error.response.data,
          })

          if (error.response.status === 500)
            Sentry.captureMessage(`API Error: ${error.response.data}`, 'error')
        }

        Sentry.captureException(error)
      })
    }
    else {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  },
)

function formatFormData(formData: FormData): Record<string, any> {
  const obj: Record<string, any> = {}

  formData.forEach((value, key) => {
    obj[key] = value
  })

  return obj
}

export default axios
