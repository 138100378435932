import mixpanel from 'mixpanel-browser'
import { useRoute } from 'vue-router'
import { version } from '../../package.json'
import { useAuthStore } from '@/@core/stores/auth'

const VITE_MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
const SHOULD_TRACK = VITE_MIXPANEL_TOKEN && VITE_MIXPANEL_TOKEN !== 'false'

export function initializeMixpanel() {
  if (SHOULD_TRACK) {
    mixpanel.init(VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.MODE === 'development',
      loaded: identifyUserMixpanel,
    })
  }
}

export function identifyUserMixpanel() {
  if (!SHOULD_TRACK)
    return
  const { user } = useAuthStore()
  if (user && user.id) {
    mixpanel.identify(user.id.toString())
    mixpanel.people.set({
      $email: user.email,
      $username: user.username,
      $first_name: user.first_name,
      $last_name: user.last_name,
      $last_login: new Date(),
    })
  }
  else {
    const anonymousId = mixpanel.get_distinct_id()

    mixpanel.identify(anonymousId)
  }
}

export function track(event: string, properties?: Record<string, unknown>) {
  if (!SHOULD_TRACK)
    return
  let route: any = {}
  try {
    route = useRoute()
  }
  catch (e) {
    console.warn(e)
  }

  mixpanel.track(event, {
    ...properties,
    environment: import.meta.env.MODE,
    version,
    route: route?.path,
    from_frame: !!window.frameElement,
  })
}
