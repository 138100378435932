<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/api/axios'
import API_ENDPOINTS from '@/api/client'
import LogisticsShippingOrder from '@/views/apps/logistics/LogisticsShippingOrder.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const auth = JSON.parse(localStorage.getItem('auth') || '{}')
const accessToken = auth?.access_token || ''
const user_id = auth?.user?.id || ''
const filter_pending = 'PENDING'
const filter_completed = 'COMPLETED'

const contractsObject = ref<any[]>([])
const filterType = ref(filter_pending)
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)

const fetchContracts = async () => {
  try {
    const url = API_ENDPOINTS.GET_CONTRACTS_LOGISTIC(user_id, filterType.value)

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    contractsObject.value = res.data.map((contract: any) => ({
      id: contract.id,
      contract_id: contract.contract_id,
      pickup_address: contract.pickup_address,
      product: {
        name: contract.product.meta_product.name,
        family: contract.product.family.name,
        variety: contract.product.variety.name,
      },
      buyer: contract.buyer,
      seller: contract.seller,
      required_shipments: contract.required_shipments,
      amount: contract.amount,
      pickup_date_from: contract.pickup_date_from,
      pickup_date_to: contract.pickup_date_to,
      is_buyer: contract.is_buyer,
      is_seller: contract.is_seller,
      status: contract.status,
      is_destination: contract.is_destination,
    }))
  }
  catch (error) {
    console.error('Error fetching contracts:', error)
  }
}

watch(filterType, fetchContracts)

const showAlert = (type: string, message: string, duration: number = 10000) => {
  alert.value = { type, message }

  nextTick(() => {
    setTimeout(() => {
      const alertElement = document.querySelector('.v-alert__content')

      if (alertElement) {
        const elementPosition = alertElement.getBoundingClientRect().top + window.scrollY
        const fixedHeaderHeight = 250
        const scrollToPosition = elementPosition - fixedHeaderHeight

        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
      }
    }, 100)
  })

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration)
}

const handleContractCompletion = (contractId: string, id: number) => {
  contractsObject.value = contractsObject.value.filter(contract => contract.id !== id)

  showAlert('success', t('contractCompleted', { contractId }))
}

onMounted(fetchContracts)
</script>

<template>
  <VRow class="match-height">
    <VCol cols="12">
      <VTabs v-model="filterType">
        <VTab :value="filter_pending">
          {{ t("Pending") }}
        </VTab>
        <VTab :value="filter_completed">
          {{ t("CompletedTag") }}
        </VTab>
      </VTabs>
      <VAlert
        v-if="alert"
        :type="alert.type"
        variant="tonal"
        class="mb-4"
      >
        {{ alert.message }}
      </VAlert>
      <div
        v-if="contractsObject.length === 0"
        class="text-center py-4"
      >
        <p>{{ t('No contracts available') }}</p>
      </div>
      <div
        v-for="(contract, index) in contractsObject"
        v-else
        :key="contract.id"
      >
        <LogisticsShippingOrder
          :contract="contract"
          :is-first="index === 0"
          @contract-completed="handleContractCompletion"
        />
        <VDivider class="my-4" />
      </div>
    </VCol>
  </VRow>
</template>
