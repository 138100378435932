import { createApp } from 'vue'

import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue'
import { initializeMixpanel } from '@/boot/mixpanel'
import { registerPlugins } from '@core/utils/plugins'

// Styles
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

const pinia = createPinia()
const ENV_MODE = import.meta.env.MODE
const VITE_SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN

pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

Sentry.init({
  app,
  dsn: VITE_SENTRY_DSN,
  tracesSampleRate: 0,
  environment: ENV_MODE,
})

app.use(pinia)

initializeMixpanel()

// Register plugins
registerPlugins(app)

// Mount vue app
app.mount('#app')
