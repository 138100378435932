<script setup lang="ts">
import LogisticsContracts from '@/views/apps/logistics/LogisticsContracts.vue'
import LogisticsPMPContracts from '@/views/apps/logistics/LogisticsPMPContracts.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})
</script>

<template>
  <VRow class="match-height">
    <VCol
      cols="12"
      md="6"
    >
      <LogisticsContracts />
    </VCol>

    <VCol
      cols="12"
      md="6"
    >
      <LogisticsPMPContracts />
    </VCol>
  </VRow>
</template>
