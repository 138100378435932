{
  "UI Elements": "Elementos de UI",
  "Forms & Tables": "Formularios y Tablas",
  "Pages": "Páginas",
  "Charts & Maps": "Gráficos y Mapas",
  "Others": "Otros",
  "Typography": "Tipografía",
  "Cards": "Tarjetas",
  "Basic": "Básico",
  "Advance": "Avanzado",
  "Widgets": "Widgets",
  "Components": "Componentes",
  "Alert": "Alerta",
  "Close Alert": "Cerrar Alerta",
  "Avatar": "Avatar",
  "Badge": "Insignia",
  "Button": "Botón",
  "Calendar": "Calendario",
  "Image": "Imagen",
  "Pagination": "Paginación",
  "Progress Circular": "Progreso Circular",
  "Progress Linear": "Progreso Lineal",
  "Autocomplete": "Autocompletar",
  "Tooltip": "Tooltip",
  "Slider": "Slider",
  "Date Time Picker": "Selector de Fecha y Hora",
  "Select": "Seleccionar",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Valoración",
  "File Input": "Entrada de Archivo",
  "Otp Input": "Entrada de OTP",
  "Form Layout": "Diseño de Formulario",
  "Form Validation": "Validación de Formulario",
  "Charts": "Gráficos",
  "Apex Chart": "Gráfico Apex",
  "Chartjs": "Chartjs",
  "Account Settings": "Configuración de Cuenta",
  "User Profile": "Perfil de Usuario",
  "FAQ": "Preguntas Frecuentes",
  "Dialog Examples": "Ejemplos de Diálogo",
  "Pricing": "Precios",
  "List": "Lista",
  "Edit": "Editar",
  "Nav Levels": "Niveles de Navegación",
  "Level 2.1": "Nivel 2.1",
  "Level 2.2": "Nivel 2.2",
  "Level 3.1": "Nivel 3.1",
  "Level 3.2": "Nivel 3.2",
  "Raise Support": "Solicitar Soporte",
  "Documentation": "Documentación",
  "Dashboards": "Tableros",
  "Analytics": "Analíticas",
  "Apps & Pages": "Aplicaciones y Páginas",
  "Email": "Correo Electrónico",
  "Chat": "Chat",
  "Invoice": "Factura",
  "Preview": "Vista Previa",
  "Add": "Añadir",
  "User": "Usuario",
  "View": "Ver",
  "Login v1": "Inicio de Sesión v1",
  "Login v2": "Inicio de Sesión v2",
  "Login": "Iniciar sesión",
  "Register v1": "Registro v1",
  "Register v2": "Registro v2",
  "Register": "Registro",
  "Forget Password v1": "Olvidar Contraseña v1",
  "Forget Password v2": "Olvidar Contraseña v2",
  "Forgot Password v1": "Olvidó Contraseña v1",
  "Forgot Password v2": "Olvidó Contraseña v2",
  "Forgot Password": "Olvidó Contraseña",
  "Reset Password v1": "Restablecer Contraseña v1",
  "Reset Password v2": "Restablecer Contraseña v2",
  "Reset Password": "Restablecer Contraseña",
  "Miscellaneous": "Misceláneo",
  "Coming Soon": "Próximamente",
  "Not Authorized": "No Autorizado",
  "Under Maintenance": "En Mantenimiento",
  "Error": "Error",
  "Statistics": "Estadísticas",
  "Actions": "Acciones",
  "Access Control": "Control de Acceso",
  "User Interface": "Interfaz de Usuario",
  "CRM": "CRM",
  "eCommerce": "Comercio Electrónico",
  "Icons": "Íconos",
  "Chip": "Chip",
  "Dialog": "Diálogo",
  "Expansion Panel": "Panel de Expansión",
  "Combobox": "Combobox",
  "Textfield": "Campo de Texto",
  "Range Slider": "Slider de Rango",
  "Menu": "Menú",
  "Snackbar": "Snackbar",
  "Tabs": "Pestañas",
  "Form Elements": "Elementos de Formulario",
  "Form Layouts": "Diseños de Formulario",
  "Authentication": "Autenticación",
  "Page Not Found - 404": "Página no encontrada - 404",
  "Not Authorized - 401": "No Autorizado - 401",
  "Server Error - 500": "Error de Servidor - 500",
  "2": "2",
  "Forms": "Formularios",
  "Timeline": "Línea de Tiempo",
  "Disabled Menu": "Menú Deshabilitado",
  "Help Center": "Centro de Ayuda",
  "Verify Email": "Verificar Email",
  "Verify Email v1": "Verificar Email v1",
  "Verify Email v2": "Verificar Email v2",
  "Two Steps": "Dos Pasos",
  "Two Steps v1": "Dos Pasos v1",
  "Two Steps v2": "Dos Pasos v2",
  "Custom Input": "Input Personalizado",
  "Extensions": "Extensiones",
  "Tour": "Tour",
  "Register Multi-Steps": "Registro en Múltiples Pasos",
  "Wizard Examples": "Ejemplos de Asistente",
  "Checkout": "Pago",
  "Create Deal": "Crear Oferta",
  "Property Listing": "Listado de Propiedades",
  "Roles & Permissions": "Roles y Permisos",
  "Roles": "Roles",
  "Simple Table": "Tabla Simple",
  "Tables": "Tablas",
  "Data Table": "Tabla de Datos",
  "Permissions": "Permisos",
  "Apps": "Aplicaciones",
  "Misc": "Misceláneo",
  "Wizard Pages": "Páginas de Asistente",
  "Form Wizard": "Asistente de Formulario",
  "Numbered": "Numerado",
  "3": "3",
  "ecommerce": "comercio electrónico",
  "Ecommerce": "Comercio Electrónico",
  "Editors": "Editores",
  "Front Pages": "Páginas Frontales",
  "Landing": "Landing",
  "checkout": "pago",
  "Payment": "Pago",
  "Swiper": "Swiper",
  "Product": "Producto",
  "Category": "Categoría",
  "Order": "Orden",
  "Details": "Detalles",
  "Customer": "Cliente",
  "Manage Review": "Gestionar Reseña",
  "Referrals": "Referencias",
  "Settings": "Configuraciones",
  "Overview": "Resumen",
  "My Course": "Mi Curso",
  "Course Details": "Detalles del Curso",
  "Academy": "Academia",
  "Logistics": "Logística",
  "Dashboard": "Tablero",
  "Fleet": "Flota",
  "5": "5",
  "10": "10",
  "20": "20",
  "25": "25",
  "50": "50",
  "100": "100",
  "Contracts": "Contratos",
  "Contract": "Contrato",
  "Operation type": "Tipo de operación",
  "Business": "Empresa",
  "Commodity": "Mercancía",
  "Position": "Posición",
  "Price €/T(Tons)": "Precio (€/Tm)",
  "Amount T(Tons)": "Cant. (Tm)",
  "Start": "Inicio",
  "End": "Fin",
  "Contract status": "Estado contrato",
  "Received": "Recibido",
  "Cancelled": "Cancelado",
  "Contract completed": "Contrato finalizado",
  "Logistics_completed": "Logistica terminada",
  "Paid": "Pagado",
  "P. Date": "Forma Pago",
  "No contracts available": "No hay contratos disponibles",
  "Loading contracts...": "Cargando contratos...",
  "Downloading contract...": "Descargando contrato...",
  "Buy": "Compra",
  "Sell": "Venta",
  "You must enter an email": "Debes introducir un email",
  "It must be a valid email": "Debe ser un email válido",
  "You must enter a valid password": "Debes introducir una contraseña válida",
  "The password must be at least 8 characters long": "La contraseña debe contener al menos 8 caracteres",
  "Both Passwords must be the same": "Ambas contraseñas deben ser la misma",
  "Forgot Password? 🔒": "¿Olvidaste tu contraseña? 🔒",
  "Forgot Password?": "¿Olvidaste tu contraseña?",
  "Enter your email and we´ll send you instructions to reset your password": "Introduce tu email y te enviaremos instrucciones para restablecer tu contraseña",
  "Send Reset Link": "Enviar enlace de restablecimiento",
  "Reset Password 🔒": "Restablecer contraseña 🔒",
  "Set New Password": "Establecer contraseña",
  "Back to login": "Volver al inicio de sesión",
  "Password reset link sent successfully.": "Enlace de restablecimiento de contraseña enviado con éxito.",
  "New Password": "Nueva contraseña",
  "Confirm Password": "Confirmar contraseña",
  "Failed to send reset link. Please try again later.": "Error al enviar enlace de restablecimiento. Por favor, intentalo mas tarde.",
  "An error has occurred while resetting the password. Please, try again later.": "Se ha producido un error al restablecer la contraseña. Por favor, inténtelo de nuevo más tarde.",
  "Home": "Inicio",
  "Sold": "Vendido",
  "Bought": "Comprado",
  "January": "Enero",
  "February": "Febrero",
  "March": "Marzo",
  "April": "Abril",
  "May": "Mayo",
  "June": "Junio",
  "July": "Julio",
  "August": "Agosto",
  "September": "Septiembre",
  "October": "Octubre",
  "November": "Noviembre",
  "December": "Diciembre",
  "Average prices": "Precios medios",
  "Select Product": "Selecciona producto",
  "Welcome to AbastoresOS": "¡Bienvenido a AbastoresOS!",
  "Please sign-in to your account": "Por favor, inicia sesion con tu cuenta",
  "New on our platform?": "¿Eres nuevo en nuestra plataforma?",
  "Create an account": "Crea una cuenta",
  "Remember me": "Recuerdame",
  "Password": "Contraseña",
  "or": "o",
  "We´re currently working on enhancing the system. Please try again later.": "Estamos trabajando para mejorar el sistema. Por favor, inténtelo de nuevo más tarde.",
  "Manage trucks": "Gestionar camiones",
  "Plate": "Matrícula",
  "Date/Time": "Fecha/Hora",
  "The seller will be notified by email of the license plate number and date to pick up the goods. Are you sure?": "Se va a notificar mediante correo electrónico la matrícula y fecha para recoger la mercancía al vendedor. ¿Estás seguro?",
  "The buyer will be notified by email of the license plate number and date to pick up the goods. Are you sure?": "Se va a notificar mediante correo electrónico la matrícula y fecha para recoger la mercancía al comprador. ¿Estás seguro?",
  "Pickup date": "Fecha de recogida",
  "Cancel": "Cancelar",
  "Send": "Enviar",
  "Confirm shipping?": "¿Confirmar envío?",
  "Send to seller": "Enviar al vendedor",
  "Send to buyer": "Enviar al comprador",
  "Clear": "Limpiar",
  "Enter plate": "Introduce matrícula",
  "Enter Date/Time": "Introduce Fecha/Hora",
  "Plate is required": "La matrícula es obligatoria",
  "Date/Time is required": "La fecha/hora es obligatoria",
  "Trucks": "Camiones",
  "Name": "Nombre",
  "User successfully unlinked.": "Usuario desvinculado correctamente.",
  "There was a problem unlinking the account. Please try again later.": "Se ha producido un error al desvincular la cuenta. Por favor, inténtalo de nuevo más tarde.",
  "User added successfully.": "Usuario añadido correctamente.",
  "There was a problem adding the new user. Please try again later.": "Se ha producido un error al añadir al nuevo usuario. Por favor, inténtalo de nuevo más tarde.",
  "Add New User": "Añadir nuevo usuario",
  "Unlink User": "Desvincular usuario",
  "Are you sure you want to unlink this user from the Company?": "¿Estas seguro de desvincular al usuario de esta empresa?",
  "Search User": "Buscar Usuario",
  "All": "Todos",
  "Confirm": "Confirmar",
  "First Name": "Nombre",
  "Last Name": "Apellidos",
  "Company": "Empresa",
  "Phone Number": "Numero de Teléfono",
  "Submit": "Enviar",
  "Users": "Usuarios",
  "Showing": "Mostrando del",
  "to": "al",
  "of": "de",
  "entries": "resultados",
  "This field is required": "Este campo es obligatorio",
  "The Email field must be a valid email": "El campo de Email debe ser un correo electrónico válido",
  "Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars": "El campo debe contener al menos una letra mayúscula, una letra minúscula, un carácter especial y un dígito, con un mínimo de 8 caracteres",
  "The Confirm Password field confirmation does not match": "La confirmación del campo de Confirmar Contraseña no coincide",
  "Enter number between {min} and {max}": "Introduce un número entre {min} y {max}",
  "This field must be an integer": "Este campo debe ser un número entero",
  "The Regex field format is invalid": "El formato del campo Regex no es válido",
  "The Alpha field may only contain alphabetic characters": "El campo Alpha solo puede contener caracteres alfabéticos",
  "URL is invalid": "La URL no es válida",
  "The length of the Characters field must be {length} characters.": "La longitud del campo de Caracteres debe ser de {length} caracteres.",
  "All Character are not valid": "Todos los caracteres no son válidos",
  "Bills": "Facturas",
  "Bill Status": "Estado de la factura",
  "Start Due Date": "Fecha inicio vencimiento",
  "End Due Date": "Fecha fin vencimiento",
  "PAID": "PAGADA",
  "PENDING": "PENDIENTE",
  "OVERDUE": "VENCIDA",
  "NEW": "NUEVA",
  "Show Bills": "Mostrar facturas",
  "Due Date": "Fecha de vencimiento",
  "Status": "Estado",
  "Attach file": "Adjuntar archivo",
  "Add Bill": "Agregar factura",
  "No bills found": "No se encontraron facturas",
  "Mark as Paid": "Marcar como pagado",
  "Delete": "Eliminar",
  "Edit Bill": "Editar Factura",
  "Bill added successfully": "Factura añadida con éxito",
  "An error occurred while adding the bill. Please try again later": "Se ha producido un error al añadir la factura. Por favor, inténtalo de nuevo más tarde",
  "Please, attach a document": "Por favor, adjunta un documento",
  "Bill updated successfully": "Factura actualizada con éxito",
  "An error occurred while updating the bill. Please try again later": "Se ha producido un error al actualizar la factura. Por favor, inténtalo de nuevo más tarde",
  "Bill deleted successfully": "Factura eliminada con éxito",
  "An error occurred while deleting the bill. Please try again later": "Se ha producido un error al borrar la factura. Por favor, inténtalo de nuevo más tarde",
  "Bill marked as paid": "Factura marcada como pagada",
  "An error occurred while changing the bill status. Please try again later": "Se ha producido un error al cambiar el estado de la factura. Por favor, inténtalo de nuevo más tarde",
  "Delete Bill": "Eliminar factura",
  "Are you sure you want to delete this bill?": "¿Estás seguro de que quieres eliminar esta factura?",
  "Mark Bill as Paid": "Marcar factura como pagada",
  "Are you sure you want to mark this bill as paid?": "¿Estás seguro de que quieres marcar esta factura como pagada?",
  "An error occurred while downloading the bill. Please try again later": "An error occurred while downloading the bill. Please try again later",
  "contractCompleted": "El contrato {contractId} se ha completado. Se ha movido al listado de completados.",
  "Pending": "Pendientes",
  "CompletedTag": "Completados",
  "orderAddedSuccessfully": "El envio con matrícula {newPlate} se ha añadido correctamente",
  "There was an error when adding the plate. Please, try again later": "Ha ocurrido un error al añadir la matrícula. Por favor, intentalo de nuevo mas tarde",
  "See trucks": "Ver camiones",
  "In this operation, the other company handles the adding of the plates.": "En esta operación, la otra empresa se encarga de introducir las matrículas.",
  "No license plate data is available.": "No se disponen de datos de las matrículas.",
  "License plate data has not been entered yet.": "Aún no se han introducido datos de las matriculas.",
  "$vuetify": {
    "badge": "Insignia",
    "noDataText": "No hay datos disponibles",
    "close": "Cerrar",
    "open": "abrir",
    "loading": "cargando",
    "carousel": {
      "ariaLabel": {
        "delimiter": "delimitador"
      }
    },
    "dataFooter": {
      "itemsPerPageText": "Elementos por página:",
      "itemsPerPageAll": "Todos",
      "pageText": "{0}-{1} de {2}",
      "firstPage": "Primera Página",
      "prevPage": "Página Anterior",
      "nextPage": "Página Siguiente",
      "lastPage": "Última Página"
    },
    "pagination": {
      "ariaLabel": {
        "root": "raíz",
        "previous": "anterior",
        "first": "primera",
        "last": "última",
        "next": "siguiente",
        "currentPage": "página actual",
        "page": "página"
      }
    },
    "input": {
      "clear": "limpiar",
      "appendAction": "acción añadida",
      "prependAction": "acción prepended",
      "counterSize": "tamaño del contador",
      "otp": "OTP"
    },
    "fileInput": {
      "counterSize": "tamaño del contador"
    },
    "rating": {
      "ariaLabel": {
        "item": "ítem"
      }
    }
  }
}
