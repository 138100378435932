<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/@core/stores/auth'
import { track } from '@/boot/mixpanel'
import ConfirmationDialog from '@/pages/components/confirmation-dialog.vue'
import AddNewUserDrawer from '@/views/apps/user/list/AddNewUserDrawer.vue'
import { getUsersByCompanyId } from '@core/stores/company'
import type { UserProperties } from '@db/apps/users/types'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const searchQuery = ref('')
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const isDeleteConfirmationOpen = ref(false)
const selectedUserId = ref<number | null>(null)
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)
const isAddNewUserDrawerVisible = ref(false)

const auth = JSON.parse(localStorage.getItem('auth'))
const user_id = auth?.user?.id
const companyId = auth?.user?.company?.id

const usersData = ref({
  users: [],
  totalUsers: 0,
})

const filteredUsers = computed(() => {
  if (!searchQuery.value)
    return usersData.value.users

  return usersData.value.users.filter((user: UserProperties) =>
    user.username.toLowerCase().includes(searchQuery.value.toLowerCase()),
  )
})

const users = computed((): UserProperties[] => filteredUsers.value)
const totalUsers = computed(() => filteredUsers.value.length)

const headers = [
  { title: t('Name'), key: 'name' },
  { title: t('Actions'), key: 'actions', sortable: false },
]

const showAlert = (type: string, message: string, duration: number = 5000) => {
  alert.value = { type, message }

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration)
}

const loadUsers = async () => {
  if (!companyId)
    return

  try {
    const fetchedUsers = await getUsersByCompanyId(companyId)

    usersData.value.users = fetchedUsers.filter(user => user.id !== user_id)
    usersData.value.totalUsers = usersData.value.users.length
  }
  catch (error) {
    console.error('Error loading users:', error)
  }
}

const confirmDeleteUser = (id: number) => {
  selectedUserId.value = id
  isDeleteConfirmationOpen.value = true
}

const deleteUser = async () => {
  try {
    const authStore = useAuthStore()

    await authStore.unlink_company_user(selectedUserId.value)

    usersData.value.users = usersData.value.users.filter(user => user.id !== selectedUserId.value)
    usersData.value.totalUsers = usersData.value.users.length
    isDeleteConfirmationOpen.value = false

    console.log('User successfully unlinked.')
    showAlert('success', t('User successfully unlinked.'))
    track('User Unlinked from company')
  }
  catch (error) {
    console.error('Error deleting user:', error)

    const errorMessage = t('There was a problem unlinking the account. Please try again later.')

    showAlert('error', errorMessage)
  }
}

const addNewUser = async (userData: Omit<User, 'id' | 'company'>) => {
  try {
    const authStore = useAuthStore()

    const fullUserData = {
      ...userData,
    }

    await authStore.register_company_user(fullUserData)

    await loadUsers()
    showAlert('success', t('User added successfully.'))
    track('New company user created')
  }
  catch (error) {
    const errorMessage = t('There was a problem adding the new user. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error adding new user:', error)
  }
}

const updateOptions = (options: any) => {
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

onMounted(() => {
  loadUsers()
})

onUnmounted(() => {
  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)
})

watch(isAddNewUserDrawerVisible, newVal => {
  if (newVal)
    track('User Add Drawer Opened')
})
</script>

<template>
  <section>
    <VAlert
      v-if="alert"
      :type="alert.type"
      variant="tonal"
      class="mb-4"
    >
      {{ alert.message }}
    </VAlert>
    <VCard class="mb-6">
      <VCardText class="d-flex flex-wrap gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <div style="inline-size: 15.625rem;">
            <AppTextField
              v-model="searchQuery"
              :placeholder="t('Search User')"
            />
          </div>
          <VBtn
            prepend-icon="tabler-plus"
            @click="isAddNewUserDrawerVisible = true"
          >
            {{ t('Add New User') }}
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="users"
        :items-length="totalUsers"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div class="d-flex align-center gap-x-4">
            <div class="d-flex flex-column">
              <h6 class="text-base">
                <RouterLink
                  :to="{ name: 'apps-user-view-id', params: { id: item.id } }"
                  class="font-weight-medium text-link"
                >
                  {{ item.first_name && item.last_name ? `${item.first_name} ${item.last_name}` : item.username }}
                </RouterLink>
              </h6>
              <div class="text-sm">
                {{ item.email }}
              </div>
            </div>
          </div>
        </template>

        <template #item.actions="{ item }">
          <IconBtn @click="confirmDeleteUser(item.id)">
            <VIcon icon="tabler-trash" />
          </IconBtn>
        </template>

        <template #bottom>
          <TablePagination
            v-model:page="page"
            :items-per-page="itemsPerPage"
            :total-items="totalUsers"
          />
        </template>
      </VDataTableServer>
    </VCard>

    <AddNewUserDrawer
      v-model:isDrawerOpen="isAddNewUserDrawerVisible"
      @user-data="addNewUser"
    />
    <ConfirmationDialog
      :is-open="isDeleteConfirmationOpen"
      :title="t('Unlink User')"
      :message="t('Are you sure you want to unlink this user from the Company?')"
      @confirm="deleteUser"
      @cancel="isDeleteConfirmationOpen = false"
    />
  </section>
</template>
