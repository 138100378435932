<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/api/axios'
import API_ENDPOINTS from '@/api/client'
import { track } from '@/boot/mixpanel'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const searchQuery = ref('')
const contractsObject = ref([])
const auth = JSON.parse(localStorage.getItem('auth'))
const accessToken = auth?.access_token || ''
const user_id = auth?.user?.id || ''
const isLoading = ref(true)
const sortBy = [{ key: 'contract_id', order: 'desc' }]
const loadingMessage = ref('')

const headers = [
  { title: t('Contract'), key: 'contract_id' },
  { title: t('Commodity'), key: 'commodity' },
  { title: t('Position'), key: 'pickup_address' },
  { title: t('Price €/T(Tons)'), key: 'price' },
  { title: t('Amount T(Tons)'), key: 'amount' },
  { title: t('Start'), key: 'pickup_date_from' },
  { title: t('End'), key: 'pickup_date_to' },
  { title: t('P. Date'), key: 'payment_term.value' },
  { title: t('Contract status'), key: 'status' },
]

const getContracts = async () => {
  loadingMessage.value = t('Loading contracts...')
  isLoading.value = true
  try {
    const url = `${API_ENDPOINTS.GET_CONTRACTS}${user_id}`

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    contractsObject.value = res.data
  }
  catch (error) {
    console.error('Error al obtener contratos:', error)
  }
  finally {
    loadingMessage.value = ''
    isLoading.value = false
  }
}

const formatStatus = (status: string) => {
  if (status === 'SENT')
    status = 'RECEIVED'
  if (status === 'COMPLETED')
    status = 'CONTRACT COMPLETED'

  status = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()

  return t(status)
}

onMounted(() => {
  getContracts()
})

const downloadContract = async (id, contract_id) => {
  loadingMessage.value = t('Downloading contract...')
  isLoading.value = true
  try {
    const url = `${API_ENDPOINTS.DOWNLOAD_CONTRACT}${id}`

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })

    const blob = new Blob([res.data], { type: 'application/pdf' })
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = downloadUrl
    link.setAttribute('download', `${contract_id}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  catch (error) {
    console.error('Error downloading the contract:', error)
  }
  finally {
    loadingMessage.value = ''
    isLoading.value = false
    track('Downloaded contract', {
      contract_id,
    })
  }
}

const getStatusClass = (status: string) => {
  const statusLower = status.toLowerCase()
  switch (statusLower) {
    case 'completed':
      return 'status-completed'
    case 'cancelled':
      return 'status-cancelled'
    default:
      return 'status-default'
  }
}

const getBuySellClass = (isBuyer: boolean) => {
  return isBuyer ? 'text-buy' : 'text-sell'
}
</script>

<template>
  <section>
    <VCard>
      <VCardText>
        <VToolbar>
          <VToolbarTitle>{{ t("Contracts") }}</VToolbarTitle>
          <VSpacer />
        </VToolbar>
        <VDivider />

        <div style="position: relative;">
          <VDataTable
            v-if="contractsObject.length > 0"
            :headers="headers"
            :items="contractsObject"
            item-key="contract_id"
            :search="searchQuery"
            :sort-by="sortBy"
          >
            <template #item.contract_id="{ item }">
              <a
                href="#"
                @click.prevent="downloadContract(item.id, item.contract_id)"
              >
                {{ item.contract_id }}
              </a>
              <div
                :class="getBuySellClass(item.is_buyer)"
                class="text-sm"
              >
                {{ item.is_buyer ? t('Buy') : t('Sell') }}
              </div>
              <div class="text-sm">
                {{ item.is_buyer ? item.seller.company_name : item.buyer.company_name }}
              </div>
            </template>
            <template #item.commodity="{ item }">
              {{ item.product.meta_product.name }} - {{ item.product.variety.name }}
            </template>
            <template #item.pickup_address="{ item }">
              {{ item.pickup_address }}
            </template>
            <template #item.price="{ item }">
              {{ item.price }}
            </template>
            <template #item.amount="{ item }">
              {{ item.amount }}
            </template>
            <template #item.pickup_date_from="{ item }">
              {{ new Date(item.pickup_date_from).toLocaleDateString() }}
            </template>
            <template #item.pickup_date_to="{ item }">
              {{ new Date(item.pickup_date_to).toLocaleDateString() }}
            </template>
            <template #item.status="{ item }">
              <span :class="getStatusClass(item.status)">
                {{ t(formatStatus(item.status)) }}
              </span>
            </template>
          </VDataTable>
          <template v-else>
            {{ t("No contracts available") }}
          </template>
          <div
            v-if="isLoading"
            class="overlay"
          >
            <div class="spinner" />
            <p v-if="loadingMessage">
              {{ loadingMessage }}
            </p>
          </div>
        </div>
      </VCardText>
    </VCard>
  </section>
</template>

<style lang="scss">
#invoice-list {
  .invoice-list-actions {
    inline-size: 8rem;
  }

  .invoice-list-filter {
    inline-size: 12rem;
  }
}

.overlay {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 50%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;

  .spinner {
    border: 4px solid rgba(0, 0, 0, 10%);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    block-size: 36px;
    border-inline-start-color: #09f;
    inline-size: 36px;
  }

  p {
    color: white;
    font-size: 1.25rem;
    margin-block-start: 1rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.status-default {
  color: #3455bb;
}

.status-completed {
  color: #8fd641;
}

.status-cancelled {
  color: #f26b58;
}

.text-buy {
  color: #8fd641;
}

.text-sell {
  color: #3455bb;
}
</style>
