<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/@core/stores/auth'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

import authV2LoginIllustrationDark from '@images/pages/auth-v2-login-abastores-illustration-dark.png'
import authV2LoginIllustrationLight from '@images/pages/auth-v2-login-abastores-illustration-light.png'

const authThemeImg = useGenerateImageVariant(authV2LoginIllustrationLight, authV2LoginIllustrationDark)
const { t } = useI18n()
const authStore = useAuthStore()
const loading = ref(false)

interface FormState {
  email: string
}

const form = reactive<FormState>({
  email: '',
})

const errors = reactive({
  email: '',
  isValid: false,
})

const validate = ({ email }) => {
  errors.email = ''
  errors.isValid = false
  if (!email.trim()) {
    errors.email = t('You must enter an email')

    return false
  }
  else if (!/^\S[^\s@]*@\S[^\s.]*\.\S+$/.test(email.trim())) {
    errors.email = t('It must be a valid email')

    return false
  }
  errors.isValid = true

  return true
}

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const onSubmit = async () => {
  loading.value = true

  const formData = { email: form.email.trim() }
  const isValid = validate(formData)
  if (!isValid) {
    loading.value = false

    return
  }

  try {
    if (authStore) {
      authStore.access_token = ''
      authStore.refresh_token = ''
    }
    await authStore.forgot_password(form.email.trim(), 'AbastoresOS')
    errors.email = t('Password reset link sent successfully.')
    setTimeout(() => {
      errors.email = ''
    }, 5000)
  }
  catch (error: any) {
    console.error(error)
    errors.email = t('Failed to send reset link. Please try again later.')
    errors.isValid = false
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow
    class="auth-wrapper bg-surface"
    no-gutters
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 150px;"
        >
          <VImg
            max-width="468"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <h4 class="text-h4 mb-1">
            {{ t('Forgot Password? 🔒') }}
          </h4>
          <p class="mb-0">
            {{ t('Enter your email and we´ll send you instructions to reset your password') }}
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="onSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                  :error-message="errors.email"
                />
              </VCol>

              <VCol
                v-if="errors.email"
                cols="12"
              >
                <div
                  v-if="errors.isValid"
                  class="success-message"
                >
                  {{ errors.email }}
                </div>
                <div
                  v-else
                  class="error-message"
                >
                  {{ errors.email }}
                </div>
              </VCol>
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                >
                  {{ t('Send Reset Link') }}
                </VBtn>
              </VCol>
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    size="20"
                    class="me-1 flip-in-rtl"
                  />
                  <span>{{ t('Back to login') }}</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.error-message {
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  margin-block-start: 8px;
  padding-block: 8px;
  padding-inline: 12px;
}

.success-message {
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  margin-block-start: 8px;
  padding-block: 8px;
  padding-inline: 12px;
}
</style>
