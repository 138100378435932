<script setup lang="ts">
import type { LngLatLike } from 'mapbox-gl'
import mapboxgl from 'mapbox-gl'
import { onMounted, ref, watch } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { useDisplay } from 'vuetify'
import fleetImg from '@images/misc/fleet-car-azul.png'

const { isLeftSidebarOpen } = useResponsiveLeftSidebar()

const accessToken = 'pk.eyJ1Ijoic29jaWFsZXhwbG9yZXIiLCJhIjoiREFQbXBISSJ9.dwFTwfSaWsHvktHrRtpydQ'
const map = ref<mapboxgl.Map>()
const vuetifyDisplay = useDisplay()

definePage({
  meta: {
    layoutWrapperClasses: 'layout-content-height-fixed',
  },
})

const carImgs = ref([fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg, fleetImg])
const refCars = ref<HTMLElement[]>([])
const showPanel = ref([true, false, false, false, false, false, false, false, false, false, false])

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-6.3727, 39.4763],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2.1374, 40.0704],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [1.2551, 41.1189],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0.0457, 38.6448],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4.6247, 36.5399],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4.7286, 41.6524],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4.0226, 39.8628],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4.7792, 37.8882],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-3.163, 40.6294],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-5.8492, 43.3619],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2.9349, 43.2630],
      },
    },
  ],
}

const activeIndex = ref(0)

const drawRoute = (start: number[1], end: number[2]) => {
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${start.join(',')};${end.join(',')}?geometries=geojson&access_token=${accessToken}`

  fetch(url)
    .then(response => response.json())
    .then(data => {
      const route = data.routes[0].geometry.coordinates

      if (map.value.getSource('route')) {
        map.value.getSource('route').setData({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: route,
          },
        })
      }
      else {
        map.value.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        })

        map.value.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#34558B',
            'line-width': 5,
          },
        })
      }
    })
}

onMounted(() => {
  mapboxgl.accessToken = accessToken

  map.value = new mapboxgl.Map({
    container: 'mapContainer',
    style: 'mapbox://styles/mapbox/light-v9',
    center: [-3.3673, 40.4821],
    zoom: 6.25,
  })

  map.value.on('load', () => {
    geojson.features.forEach((feature, index) => {
      const el = document.createElement('div')

      el.className = 'marker'
      el.style.backgroundImage = `url(${carImgs.value[index]})`
      el.style.width = '55px'
      el.style.height = '20px'
      el.style.backgroundSize = 'contain'
      new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates as LngLatLike).addTo(map.value)
    })

    refCars.value[activeIndex.value]?.classList.add('marker-focus')
  })
})

const vehicleTrackingData = [
  {
    name: 'ABTS/20240528_01',
    location: 'Cáceres, Spain',
    progress: 88,
    driverName: 'Cesar Hernandez',
  },
  {
    name: 'ABTS/20240525_04',
    location: 'Cuenca, Spain',
    progress: 100,
    driverName: 'Jesus Soldea',
  },
  {
    name: 'ABTS/20240410_04',
    location: 'Tarragona, Spain',
    progress: 60,
    driverName: 'Sergio Canales',
  },
  {
    name: 'ABTS/20240318_01',
    location: 'Calpe, Spain',
    progress: 28,
    driverName: 'Rafael Jemez',
  },
  {
    name: 'ABTS/20240508_02',
    location: 'Fuengirola, Spain',
    progress: 28,
    driverName: 'Jose Bermejo',
  },
  {
    name: 'ABTS/20240328_05',
    location: 'Valladolid, Spain',
    progress: 28,
    driverName: 'Santiago Redondo',
  },
  {
    name: 'ABTS/20240518_08',
    location: 'Toledo, Spain',
    progress: 28,
    driverName: 'Alberto Moreno',
  },
  {
    name: 'ABTS/20240518_08',
    location: 'Cordoba, Spain',
    progress: 28,
    driverName: 'Marta Magan',
  },
  {
    name: 'ABTS/20240518_08',
    location: 'Guadalajara, Spain',
    progress: 28,
    driverName: 'Jesus Argo',
  },
  {
    name: 'ABTS/20240218_08',
    location: 'Asturias, Spain',
    progress: 28,
    driverName: 'Miguel Roman',
  },
  {
    name: 'ABTS/20240218_08',
    location: 'Bilbao, Spain',
    progress: 28,
    driverName: 'Iñaki Arrizabalaga',
  },
]

const flyToLocation = (geolocation: number[], index: number) => {
  activeIndex.value = index
  showPanel.value.fill(false)
  showPanel.value[index] = !showPanel.value[index]

  if (vuetifyDisplay.mdAndDown.value)
    isLeftSidebarOpen.value = false

  map.value.flyTo({
    center: geolocation,
    zoom: 16,
  })

  const start = geojson.features[0].geometry.coordinates
  const end = geojson.features[index].geometry.coordinates

  drawRoute(start, end)
}

watch(activeIndex, () => {
  refCars.value.forEach((car, index) => {
    if (index === activeIndex.value)
      car.classList.add('marker-focus')
    else car.classList.remove('marker-focus')
  })
})
</script>

<template>
  <VLayout class="fleet-app-layout">
    <VNavigationDrawer
      v-model="isLeftSidebarOpen"
      width="320"
      absolute
      touchless
      location="start"
    >
      <VCard
        class="h-100 fleet-navigation-drawer"
        flat
      >
        <VCardItem>
          <VCardTitle>Truck locator</VCardTitle>
          <template #append>
            <IconBtn
              class="d-lg-none navigation-close-btn"
              @click="isLeftSidebarOpen = !isLeftSidebarOpen"
            >
              <VIcon icon="tabler-x" />
            </IconBtn>
          </template>
        </VCardItem>

        <PerfectScrollbar
          :options="{ wheelPropagation: false, suppressScrollX: true }"
          style="block-size: calc(100% - 60px);"
        >
          <VCardText class="pt-0">
            <div
              v-for="(vehicle, index) in vehicleTrackingData"
              :key="index"
              class="mb-6"
            >
              <div
                class="d-flex align-center justify-space-between cursor-pointer"
                @click="flyToLocation(geojson.features[index].geometry.coordinates, index)"
              >
                <div class="d-flex gap-x-4">
                  <VAvatar
                    icon="tabler-truck"
                    variant="tonal"
                  />
                  <div>
                    <div class="text-body-1 text-high-emphasis font-weight-medium">
                      {{ vehicle.name }}
                    </div>
                    <div class="text-body-1 text-disabled">
                      {{ vehicle.location }}
                    </div>
                  </div>
                </div>
                <IconBtn density="comfortable">
                  <VIcon :icon="showPanel[index] ? 'tabler-chevron-down' : $vuetify.locale.isRtl ? 'tabler-chevron-left' : 'tabler-chevron-right'" />
                </IconBtn>
              </div>
              <VExpandTransition mode="out-in">
                <div v-show="showPanel[index]">
                  <div class="py-4 mb-4">
                    <div class="d-flex justify-space-between mb-2">
                      <span class="text-body-1 text-high-emphasis">Delivery Process</span>
                      <span class="text-body-2">{{ vehicle.progress }}%</span>
                    </div>
                    <VProgressLinear
                      :model-value="vehicle.progress"
                      color="primary"
                      rounded
                      height="6"
                    />
                  </div>
                  <div>
                    <VTimeline
                      align="start"
                      truncate-line="both"
                      side="end"
                      density="compact"
                      line-thickness="1"
                      class="ps-2"
                    >
                      <VTimelineItem
                        icon="tabler-circle-check"
                        dot-color="rgb(var(--v-theme-surface))"
                        icon-color="success"
                        fill-dot
                        size="22"
                        :elevation="0"
                      >
                        <div>
                          Traking number created
                        </div>
                        <div class="app-timeline-title">
                          {{ vehicle.driverName }}
                        </div>
                        <div class="app-timeline-text">
                          Sep 01, 7:53 AM
                        </div>
                      </VTimelineItem>
                      <VTimelineItem
                        icon="tabler-circle-check"
                        dot-color="rgb(var(--v-theme-surface))"
                        icon-color="success"
                        fill-dot
                        size="22"
                        :elevation="0"
                      >
                        <div>
                          Out of Delivery
                        </div>
                        <div class="app-timeline-title">
                          Fernando Moreno
                        </div>
                        <div class="app-timeline-text">
                          Sep 03, 8:02 AM
                        </div>
                      </VTimelineItem>
                      <VTimelineItem
                        icon="tabler-map-pin"
                        dot-color="rgb(var(--v-theme-surface))"
                        icon-color="primary"
                        fill-dot
                        size="22"
                        :elevation="0"
                      >
                        <div>
                          Arrived
                        </div>
                        <div class="app-timeline-title">
                          Pablo Muñoz
                        </div>
                        <div class="app-timeline-text">
                          Sep 04, 8:18 AM
                        </div>
                      </VTimelineItem>
                    </VTimeline>
                  </div>
                </div>
              </VExpandTransition>
            </div>
          </VCardText>
        </PerfectScrollbar>
      </VCard>
    </VNavigationDrawer>

    <VMain>
      <div class="h-100">
        <IconBtn
          class="d-lg-none navigation-toggle-btn rounded-sm"
          variant="elevated"
          @click="isLeftSidebarOpen = true"
        >
          <VIcon icon="tabler-menu-2" />
        </IconBtn>
        <div
          id="mapContainer"
          class="basemap"
        />
      </div>
    </VMain>
  </VLayout>
</template>

<style lang="scss">
@use "@styles/variables/_vuetify.scss";
@use "@core/scss/base/_mixins.scss";
@import "mapbox-gl/dist/mapbox-gl.css";

.fleet-app-layout {
  border-radius: vuetify.$card-border-radius;

  @include mixins.elevation(vuetify.$card-elevation);

  $sel-fleet-app-layout: &;

  @at-root {
    .skin--bordered {
      @include mixins.bordered-skin($sel-fleet-app-layout);
    }
  }
}

.navigation-toggle-btn {
  position: absolute;
  z-index: 1;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
}

.navigation-close-btn {
  position: absolute;
  z-index: 1;
  inset-block-start: 1rem;
  inset-inline-end: 1rem;
}

.basemap {
  block-size: 100%;
  inline-size: 100%;
}

.marker-focus {
  filter: drop-shadow(0 0 7px rgb(var(--v-theme-primary)));
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.fleet-navigation-drawer .v-timeline .v-timeline-divider__dot .v-timeline-divider__inner-dot {
  box-shadow: none;
}

#mapContainer {
  block-size: 50vh !important;
}
</style>
