<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/@core/stores/auth'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

import authV2ResetPasswordIllustrationDark from '@images/pages/auth-v2-login-abastores-illustration-dark.png'
import authV2ResetPasswordIllustrationLight from '@images/pages/auth-v2-login-abastores-illustration-light.png'

definePage({
  meta: {
    layout: 'blank',
  },
})

const { t } = useI18n()

interface FormState {
  email: string
  new_password1: string
  new_password2: string
}

const form = reactive<FormState>({
  email: '',
  new_password1: '',
  new_password2: '',
})

const errors = reactive({
  email: '',
  isValid: false,
})

const authThemeImg = useGenerateImageVariant(authV2ResetPasswordIllustrationLight,
  authV2ResetPasswordIllustrationDark,
)

const isNewPassword1Visible = ref(false)
const isNewPassword2Visible = ref(false)
const router = useRouter()
const authStore = useAuthStore()

const { uid, token }: { uid?: string; token?: string }
  = router.currentRoute.value.query

if (!uid || !token)
  router.push('/')

const validate = (formData): boolean => {
  errors.isValid = false
  errors.email = ''

  if (!formData.email.trim()) {
    errors.email = t('You must enter an email')

    return false
  }
  else if (!/^\S[^\s@]*@\S[^\s.]*\.\S+$/.test(formData.email.trim())) {
    errors.email = t('It must be a valid email')

    return false
  }

  if (!formData.new_password1) {
    errors.email = t('You must enter a valid password')

    return false
  }

  if (!formData.new_password2) {
    errors.email = t('You must enter a valid password')

    return false
  }

  if (formData.new_password1 !== form.new_password2) {
    errors.email = t('Both Passwords must be the same')

    return false
  }
  errors.isValid = true

  return true
}

const handleSubmit = async () => {
  try {
    if (!uid || !token) {
      router.push({ name: 'login' })

      return
    }

    const formData = { email: form.email.trim(), new_password1: form.new_password1, new_password2: form.new_password2 }
    const isValid = validate(formData)
    if (!isValid)
      return

    const response = await authStore.reset_password(
      formData.email,
      formData.new_password1,
      formData.new_password2,
      uid,
      token,
    )

    if (response && response.status === 200) {
      await authStore.login(formData.email, formData.new_password1)
      router.push({ name: 'dashboards-home' })
      console.log('Password reset successful and user logged in.')
    }
    else {
      console.error('Error resetting password:', response ? response.error : 'Unknown error')
    }
  }
  catch (error) {
    console.error('Error resetting password:', error)
    errors.isValid = false
    if (error.response && error.response.data) {
      if (error.response.data.new_password2)
        errors.email = error.response.data.new_password2[0]
      else
        errors.email = t('An error has occurred while resetting the password. Please, try again later.')
    }
    else {
      errors.email = t('An error has occurred while resetting the password. Please, try again later.')
    }
  }
}
</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 150px;"
        >
          <VImg
            max-width="451"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>
      </div>
    </VCol>
    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-6"
      >
        <VCardText>
          <h4 class="text-h4 mb-1">
            {{ t('Reset Password 🔒') }}
          </h4>
        </VCardText>
        <VCardText>
          <VForm @submit.prevent="handleSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  label="Email"
                  placeholder="example@domain.com"
                  type="email"
                  :error-message="errors.email"
                />
              </VCol>
              <VCol cols="12">
                <AppTextField
                  v-model="form.new_password1"
                  autofocus
                  :label="t('New Password')"
                  placeholder="············"
                  :type="isNewPassword1Visible ? 'text' : 'password'"
                  :append-inner-icon="isNewPassword1Visible ? 'tabler-eye-off' : 'tabler-eye'"
                  :error-message="errors.email"
                  @click:append-inner="isNewPassword1Visible = !isNewPassword1Visible"
                />
              </VCol>
              <VCol cols="12">
                <AppTextField
                  v-model="form.new_password2"
                  :label="t('Confirm Password')"
                  placeholder="············"
                  :type="isNewPassword2Visible ? 'text' : 'password'"
                  :append-inner-icon="isNewPassword2Visible ? 'tabler-eye-off' : 'tabler-eye'"
                  :error-message="errors.email"
                  @click:append-inner="isNewPassword2Visible = !isNewPassword2Visible"
                />
              </VCol>
              <VCol
                v-if="errors.email"
                cols="12"
              >
                <div
                  v-if="errors.isValid"
                  class="success-message"
                >
                  {{ errors.email }}
                </div>
                <div
                  v-else
                  class="error-message"
                >
                  {{ errors.email }}
                </div>
              </VCol>
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                >
                  {{ t('Set New Password') }}
                </VBtn>
              </VCol>
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    size="20"
                    class="me-1 flip-in-rtl"
                  />
                  <span>{{ t('Back to login') }}</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.error-message {
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  margin-block-start: 8px;
  padding-block: 8px;
  padding-inline: 12px;
}

.success-message {
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  margin-block-start: 8px;
  padding-block: 8px;
  padding-inline: 12px;
}
</style>
