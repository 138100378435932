<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/@core/stores/auth'
import { track } from '@/boot/mixpanel'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import authV2LoginIllustrationDark from '@images/pages/auth-v2-login-abastores-illustration-dark.png'
import authV2LoginIllustrationLight from '@images/pages/auth-v2-login-abastores-illustration-light.png'
import authV2LoginIllustrationBorderedDark from '@images/pages/auth-v2-login-illustration-bordered-dark.png'
import authV2LoginIllustrationBorderedLight from '@images/pages/auth-v2-login-illustration-bordered-light.png'
import { themeConfig } from '@themeConfig'

const authThemeImg = useGenerateImageVariant(
  authV2LoginIllustrationLight,
  authV2LoginIllustrationDark,
  authV2LoginIllustrationBorderedLight,
  authV2LoginIllustrationBorderedDark,
  true,
)

const { t } = useI18n()

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const isPasswordVisible = ref(false)
interface FormState {
  email: string
  password: string
  remember: boolean
}

const errors = reactive<{ email: string; password: string }>({
  email: '',
  password: '',
})

const form = reactive<FormState>({
  email: '',
  password: '',
  remember: false,
})

const validate = ({ email, password }: FormState): boolean => {
  let isValid = true
  if (!email.trim()) {
    errors.email = t('You must enter an email')
    isValid = false
  }
  else if (!/^\S[^\s@]*@\S[^\s.]*\.\S+$/.test(email.trim())) {
    errors.email = t('It must be a valid email')
    isValid = false
  }
  else {
    errors.email = ''
  }

  if (!password) {
    errors.password = t('You must enter a valid password')
    isValid = false
  }
  else if (password.length < 8) {
    errors.password = t('The password must be at least 8 characters long')
    isValid = false
  }
  else {
    errors.password = ''
  }

  return isValid
}

const authStore = useAuthStore()
const router = useRouter()
const loading = ref(false)
const nonFieldErrors = ref<string[]>([])

const onSubmit = async () => {
  loading.value = true
  errors.email = ''
  errors.password = ''
  nonFieldErrors.value = []

  const formData = { email: form.email.trim(), password: form.password, remember: form.remember }
  const isValid = validate(formData)
  if (!isValid) {
    loading.value = false

    return
  }

  try {
    authStore.access_token = ''
    authStore.refresh_token = ''

    await authStore.login(formData.email, formData.password)

    nonFieldErrors.value = []
    router.replace(
      router.currentRoute.value.query.next
        ? router.currentRoute.value.query.next.toString()
        : { name: 'dashboards-home' },
    )
  }
  catch (error: any) {
    console.error(error)
    nonFieldErrors.value = error.response?.data?.non_field_errors ?? [
      t('We´re currently working on enhancing the system. Please try again later.'),
    ]
  }
  finally {
    loading.value = false
    track('Logged in')
  }
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-logo-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 6.25rem;"
        >
          <VImg
            max-width="613"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <h4 class="text-h4 mb-1">
            {{ t ("Welcome to AbastoresOS") }}<span class="text-capitalize"> {{ themeConfig.app.title }} </span> 👋🏻
          </h4>
          <p class="mb-0">
            {{ t('Please sign-in to your account') }}
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="onSubmit">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  label="Email"
                  placeholder="johndoe@email.com"
                  type="email"
                  autofocus
                />
                <div
                  v-if="errors.email"
                  class="error-message"
                >
                  {{ errors.email }}
                </div>
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  :label="t('Password')"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
                <div
                  v-if="errors.password"
                  class="error-message"
                >
                  {{ errors.password }}
                </div>

                <!-- Error message from server -->
                <VCol cols="12">
                  <div
                    v-if="nonFieldErrors.length"
                    class="error-message"
                  >
                    {{ nonFieldErrors[0] }}
                  </div>
                </VCol>

                <div class="d-flex align-center flex-wrap justify-space-between my-6">
                  <VCheckbox
                    v-model="form.remember"
                    :label="t('Remember me')"
                  />
                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    {{ t('Forgot Password?') }}
                  </RouterLink>
                </div>
              </VCol>

              <!-- Submit button -->
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                >
                  {{ t('Login') }}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.error-message {
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  margin-block-start: 8px;
  padding-block: 8px;
  padding-inline: 12px;
}
</style>
