import type { Router } from 'vue-router'
import { useAuthStore } from '@/@core/stores/auth'

export const setupGuards = (router: Router) => {
  router.beforeEach(async to => {
    if (to.meta.public)
      return

    const authStore = useAuthStore()
    const isLoggedIn = authStore.isAccessTokenValid

    try {
      if (to.meta.unauthenticatedOnly) {
        if (isLoggedIn)
          return { name: 'dashboards-home' }

        return
      }

      if (to.meta.authenticatedOnly) {
        if (!isLoggedIn) {
          if (authStore.isrefresh_tokenValid)
            await authStore.refresh()
          else
            return { name: 'login' }
        }

        if (authStore.isAccessTokenValid) {
          try {
            await authStore.fetchUserData()
          }
          catch (error) {
            console.error('Error cargando datos del usuario:', error)

            return { name: 'login' }
          }
        }
      }
    }
    catch (error) {
      console.error('Error:', error)

      return { name: 'login' }
    }
  })
}
